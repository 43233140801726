<template>
<div>
  <b-container fluid>

  <b-row>
     <!-- <div class="col-md-2 pr-md-0">

  </div> -->
  <div class="auth-wrapper auth-v1 px-1 mx-auto col-lg-9 register">
    <div class="row mt-2">
      <b-col class="text-center">  
        <!-- Brand logo-->
        <b-link class="brand-logo" href="/">
          <img src="@/assets/images/logo/logo.svg" alt="logo" height="60" />
          <!-- <h2 class="brand-text text-primary ml-1">
            Bubblin
          </h2> -->
        </b-link>
        <!-- /Brand logo-->
    </b-col>
    </div>

    <div class="auth-inner pt-2">
      
      <form-wizard color="#4D25BC" ref="registerwizard" :title="null" :subtitle="null" finish-button-text="Signer" 
      back-button-text="Précédent" next-button-text="Suivant" class="steps-transparent forms-space justify-content-center" @on-complete="formSubmitted">

         <!-- Formule -->
        <tab-content title="Formule" icon="feather icon-package" :before-change="validationPackForm"> 
           <!-- <b-alert
              v-if="showBande(form.code_promo) "
              show variant="warning" 
              class="px-1 py-1 pr-0 w-75">
              Adhésion réalisée avec le code {{form.code_promo}} vous permettant de bénéficier de {{ getValuePromoByCode(form.code_promo) }}.
           </b-alert> -->
          <!--- tab fomule description--->
          <b-row>
            <b-col sm="12" class="mb-2">
              <h3><feather-icon class="box-icon-text" icon="ArrowDownRightIcon" size="25" />Une <span class="text-primary font-weight-bolder">Marketplace</span> pour ouvrir des codes (SwissLife, Harmonie Mutuelle,…), réaliser des devis et souscrire en ligne</h3>
              <h3><feather-icon class="box-icon-text" icon="ArrowDownRightIcon" size="25" />Un <span class="text-primary font-weight-bolder">CRM</span> pour gérer clients et prospects</h3>
              <h3><feather-icon class="box-icon-text" icon="ArrowDownRightIcon" size="25" />Un <span class="text-primary font-weight-bolder">Coffre-fort conformité</span> pour répondre à vos obligations (DDA, Autorégulation…)</h3>
              <h3><feather-icon class="box-icon-text" icon="ArrowDownRightIcon" size="25" />Des outils de <span class="text-primary font-weight-bolder">Gestion de votre cabinet</span></h3>
            </b-col>
          </b-row>
          <!-- tab title formule--->
          <div class="text-center">
            <h1 class="mt-0 mb-2">
              Choisissez votre formule
            </h1>
          </div>
          <b-row>
            <b-col sm="12" class="mx-auto">
                <validation-observer ref="packRules" tag="form">
                  <validation-provider
                    #default="{ errors }"
                    name="Formule"
                    rules="required"
                  >
                    <input type="hidden" v-model="form.selected_pack" id="pack_selected" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger text-lowercase text-center d-block mb-1" v-if="errors.length > 0">veuillez sélectionner une formule</small>
                  </validation-provider>
                </validation-observer>
                    
            </b-col>
          </b-row>

          <b-row class="pricing-card">
            <b-col
              sm="8"
              md="8"
              lg="12"
              class="mx-auto"
            >
            <!-- class="match-height" -->
              <b-row class="match-height">
                <b-col sm="12" lg="4" v-for="pack in packs" :key="pack.id">
                  <b-card
                    :class="form.selected_pack == pack.id ? 'popular' : ''"
                    align="center" footer-class="py-0"
                  >
                    <h3>BUBBLE{{ pack.id }}</h3>
                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ pack.tarif }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">TTC/mois</sub>
                      </div>
                    </div>
                    <!-- <b-card-text style="font-size:1.1rem;font-weight:bold;">{{pack.description_utilisateurs}}</b-card-text> -->
                    <template v-if="pack.id == 1">
                      <b-card-text style="font-size:1.1rem;font-weight:bold;">De <span class="text-primary">1 à 5</span> utilisateurs</b-card-text>
                    </template>
                    <template v-else-if="pack.id == 2">
                      <b-card-text style="font-size:1.1rem;font-weight:bold;">De <span class="text-primary">6 à 10</span> utilisateurs</b-card-text>
                    </template>
                    <template v-else-if="pack.id == 3">
                      <b-card-text style="font-size:1.1rem;font-weight:bold;">Nombre <span class="text-primary">illimité</span> d'utilisateurs</b-card-text>
                    </template>
                    <b-card-text style="font-size:0.7rem;font-weight: 600;font-style:italic;">Ce tarif inclut l'adhésion à l'association La plateforme du Courtage</b-card-text>
                    <template #footer>
                  <div class="d-flex flex-column bd-highlight">
  <div class="py-1 bd-highlight">
       <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        class="text-uppercase"
                        variant="primary"
                        @click="selectPack(pack.id,pack.nom,pack.tarif)"
                      >
                        Je profite de l'offre test
                      </b-button>
  </div>
</div>
                   
                </template>
                    <!-- <template v-else>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        class="mt-2"
                        variant="outline-primary"
                        disabled
                      >
                        Prochainement
                      </b-button>  
                    </template> -->
                    
                  </b-card>
                </b-col>
                
              </b-row>
            </b-col>
          </b-row>
        </tab-content>
        
        <!-- Cabinet -->
        <tab-content title="Cabinet" icon="feather icon-file-text" :before-change="validationForm">
           
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col class="mx-auto" sm="10">
                <b-row>
                  <b-col>
                    <h3 class="font-weight-bolder text-center mb-2 mt-2">Votre cabinet</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" offset-md="3">
                    <b-form-group label="N° ORIAS*" label-for="num_orias">
                      <validation-provider
                      #default="{ errors }"
                      name="N° ORIAS"
                      rules="required"
                    >
                      <b-input-group>
                        <b-form-input @keypress="onlyNumber" placeholder="N° ORIAS" id="num_orias" v-model="form.cabinet.numero_orias" :state="errors.length > 0 || !validorias ? false : null"/>
                      </b-input-group>
                      <small class="text-danger text-lowercase">
                        <template v-if="!validorias && errors.length == 0">
                          Le numéro d'orias est invalide.
                        </template>
                        <template v-else-if="errors.length > 0">
                          {{ errors[0] }}
                        </template>
                      </small>
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- v-if="!showcabinet" -->
                <b-row v-if="!orias_isVerified">
                  <b-col md="6" offset-md="3" class="text-right">
                    <b-button variant="primary" :disabled="disabled_orias" @click="GetOrias">
                      Vérifier mon orias
                    </b-button>
                  </b-col>
                </b-row>
                <template>
                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="RAISON SOCIALE*" label-for="raison_sociale">
                      <validation-provider
                      #default="{ errors }"
                      name="Raison sociale"
                      rules="required"
                    >
                      <b-form-input  id="raison_sociale" v-model="form.cabinet.raison_sociale" :state="errors.length > 0 ? false : null" placeholder="Raison sociale" />
                      <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="DENOMINATION COMMERCIALE" label-for="denomination_commercial">
                        <b-form-input id="denomination_commercial" v-model="form.cabinet.denomination_commercial" placeholder="DENOMINATION COMMERCIALE" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="N° SIREN*" label-for="num_siren">
                      <validation-provider
                      #default="{ errors }"
                      name="N° SIREN"
                      rules="required"
                    >
                      <b-form-input  id="num_siren" v-model="form.cabinet.numero_siren" :state="errors.length > 0 ? false : null" placeholder="N° SIREN" />
                      <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="LIEU RCS*" label-for="rcs">
                      <validation-provider #default="{ errors }" name="LIEU RCS" rules="required">
                        <b-form-input id="rcs" v-model="form.cabinet.lieu_rcs" placeholder="LIEU RCS" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="CAPITAL*" label-for="capital">
                      <validation-provider #default="{ errors }" name="Capital" rules="required|integer">
                        <b-form-input v-model="form.cabinet.capital" id="capital" placeholder="CAPITAL" type="number" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="FORME JURIDIQUE*" label-for="forme_juridrique">
                      <validation-provider #default="{ errors }" name="Forme Juridique" :rules="form.cabinet.form_juridique == null ? 'required' : ''">
                        <b-form-select v-model="form.cabinet.form_juridique" :state="errors.length > 0 ? false : null">
                          <b-form-select-option selected :value="null">Forme</b-form-select-option>
                          <b-form-select-option v-for="form_jur in Form_Juridique" :value="form_jur.value" :key="form_jur.value">{{ form_jur.text }}</b-form-select-option>
                        </b-form-select>

                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="NOMBRE D'EMPLOYÉS" label-for="nombre_employes">
                        <b-form-select v-model="form.cabinet.nbr_salary">
                          <b-form-select-option selected :value="null">Selectionner le nombre d'employés</b-form-select-option>
                          <b-form-select-option v-for="nombre_employe in Nombre_employes" :value="nombre_employe.value" :key="nombre_employe.value">{{ nombre_employe.text }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showcabinet">
                  <b-col md="12">
                    <b-form-group label="N° ET LIBELLÉ DE LA VOIE*" label-for="num_libelle">
                      <validation-provider #default="{ errors }" name="N° ET LIBELLÉ DE LA VOIE" rules="required">
                        <b-form-input id="num_libelle" placeholder="N° ET LIBELLÉ DE LA VOIE" v-model="form.cabinet.num_libelle" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="COMPLÉMENT D'ADRESSE" label-for="comp_addr">
                      <b-form-input id="comp_addr" placeholder="COMPLÉMENT D'ADRESSE" v-model="form.cabinet.complement_adresse" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="LIEU-DIT OU BP" label-for="lieu_dit">
                      <b-form-input id="lieu_dit" v-model="form.cabinet.lieu_dit_ou_bp" placeholder="LIEU-DIT OU BP" text="ville" />
                    </b-form-group>
                  </b-col>
                </b-row>

                

                <b-row v-show="showcabinet">
                  <b-col md="6">
                    <b-form-group label="CODE POSTAL*" label-for="code_postal">
                      <validation-provider #default="{ errors }" name="CODE POSTAL" rules="required|digits:5">
                        <b-form-input v-model="form.cabinet.code_postal" id="code_postal" placeholder="CODE POSTAL" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="VILLE*" label-for="ville">
                      <validation-provider #default="{ errors }" name="Ville" rules="required">
                        <b-form-select v-model="form.cabinet.ville" :state="errors.length > 0 ? false : null">
                          <b-form-select-option selected :value="null">Selectionner une ville</b-form-select-option>
                          <b-form-select-option v-for="ville in Villes" :value="ville.id" :key="ville.id">{{ ville.ville }}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-show="showcabinet">
                  <b-col md="6">
                <b-form-group label="TÉLÉPHONE" label-for="tel">
                    <div class="d-flex bd-highlight">
                        <div class="w-25 bd-highlight">
                              <v-select v-model="form.cabinet.indicatif" :close-on-select="true" :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                                input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                            >
                                <template #option="{ indicatif,code }">
                                  <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                                </template>
                                <template #selected-option="{ indicatif }">
                                  <span> {{ indicatif }}</span>
                                </template>
                                <div slot="no-options">Aucune indicatif disponible.</div>
                            </v-select>
                        </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider #default="{ errors }" name="TÉLÉPHONE" :rules="{regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}">                              
                        <b-form-input id="tel"  style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" v-mask="'## ## ## ## ##'" v-model="form.cabinet.telephone_cabinet" placeholder="TÉLÉPHONE" type="tel" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
            </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="EMAIL" label-for="email_cabinet">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-input id="email_cabinet" v-model="form.cabinet.email_cabinet" placeholder="Email" :state="errors.length > 0 ? false : null" type="email" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                </b-row>
                </template>
              </b-col>
            </b-row>
            
          </validation-observer>
        </tab-content>
        
         <!-- Contact dirigeant -->
        <tab-content title="Contact" icon="feather icon-user" :before-change="validationFormInfo">
            
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col class="mx-auto" sm="10">
                <b-row>
                  <b-col>
                    <h3 class="font-weight-bolder text-center mb-2 mt-2">Le dirigeant</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="CIVILITE*" label-for="civilite">
                      <validation-provider #default="{ errors }" name="Civilite" :rules="form.dirigeant.civilite == null ? 'required' : ''">
                        <b-form-select id="civilite" v-model="form.dirigeant.civilite" :state="errors.length > 0 ? false : null">
                          <b-form-select-option selected :value="null">Selectionner une civilite</b-form-select-option>
                          <b-form-select-option v-for="civilite in civilites" :value="civilite.value" :key="civilite.value">{{ civilite.text }}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="PRÉNOM*" label-for="prenom">
                      <validation-provider #default="{ errors }" name="Prénom" rules="required|min:2">
                        <b-form-input class="text-capitalize" id="prenom" v-model="form.dirigeant.prenom" placeholder="Prénom" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="NOM*" label-for="nom">
                      <validation-provider #default="{ errors }" name="Nom" rules="required|min:2">
                        <b-form-input class="text-uppercase" id="nom" v-model="form.dirigeant.nom" placeholder="Nom" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>  
                  <b-col md="4">
                   <b-form-group label="TÉLÉPHONE PORTABLE*" label-for="telephone_admin">
                      <div class="d-flex bd-highlight">
                        <div class="w-25 bd-highlight">
                              <v-select v-model="form.dirigeant.indicatif" :close-on-select="true" :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                                input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                            >
                                <template #option="{ indicatif,code }">
                                  <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                                </template>
                                <template #selected-option="{ indicatif }">
                                  <span> {{ indicatif }}</span>
                                </template>
                                <div slot="no-options">Aucune indicatif disponible.</div>
                            </v-select>
                        </div>
                      
                        <div class="w-75 bd-highlight">
                          <validation-provider #default="{ errors }" name="TÉLÉPHONE PORTABLE" rules="required|min:14" :rules="{ regex: /^(0|6|7)\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                            <b-form-input id="telephone_admin" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" v-mask="'## ## ## ## ##'" v-model="form.dirigeant.telephone_admin" placeholder="TÉLÉPHONE PORTABLE" type="tel" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                          </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="EMAIL*" label-for="email">
                      <validation-provider #default="{ errors }" name="Email" rules="required|email">
                        <b-form-input id="email" v-model="form.dirigeant.email_admin" placeholder="Email" type="email" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="4">
                    <b-form-group label="IDENTIFIANT DE CONNEXION" label-for="username">
                      <validation-provider #default="{ errors }" name="Identifiant de connexion" rules="required">
                        <b-form-input id="username" v-model="form.dirigeant.username" placeholder="Identifiant" :state="errors.length > 0 ? false : null" disabled/>
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-row v-if="form.is_administrateur == 'oui'">
                  <b-col>
                    <small class="text-danger">Attention. si le dirigeant est l'interlocuteur administratif du cabinet, ce numéro sera utilisé pour la signature électronique de votre adhésion.</small>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col md="4">
                    <b-form-group>
                      <label class="text-uppercase" for="is_administrateur">est-il l'interlocuteur administratif du cabinet</label>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="form.is_administrateur"
                          name="is_administrateur-radios"
                          value="oui"
                        >
                          oui
                        </b-form-radio>
                        <b-form-radio
                          v-model="form.is_administrateur"
                          name="is_administrateur-radios"
                          value="non"
                        >
                          non
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template v-if="form.is_administrateur == 'oui'">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="basic-password1">CREATION MOT DE PASSE </label>
                      <validation-provider #default="{ errors }" rules="required|password|min:12" name="Mot de passe" vid="password">
                        <b-input-group class="input-group-merge">
                          <b-form-input id="basic-password1" :type="passwordDiregent" placeholder="Mot de passe" :state="errors.length > 0 ? false : null" v-model="form.dirigeant.password" />
                          <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIconDiregent" class="cursor-pointer" @click="togglePasswordVisibilityD" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="basic-password_confirmation">CONFIRMATION MOT DE PASSE</label>
                      <validation-provider #default="{ errors }" rules="required|confirmed:password|min:12" name="confirmation de mot de passe">
                        <b-input-group class="input-group-merge">
                          <b-form-input id="basic-password_confirmation" :type="passwordDiregentCon" placeholder="Confirmation mot de passe" :state="errors.length > 0 ? false : null" v-model="form.dirigeant.passwordCon" />
                          <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIconDiregentCon" class="cursor-pointer" @click="togglePasswordVisibilityDC" />
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="errors[0] == 'confirmation de mot de passe n\'est pas identique'" class="text-danger">Le mot de passe n'est pas identique</small>
                        <small v-else class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                </template>
                  <template v-if="form.is_administrateur == 'non'">
                    <b-row>
                      <b-col>
                        <h3 class="font-weight-bolder text-center mb-2 mt-2">L'interlocuteur administratif</h3>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mb-1">
                        <small class="text-danger"> Attention, l’interlocuteur administratif est le signataire de votre adhésion. Il doit être dûment habilité à le faire. Dans le cas contraire, laissez le Dirigeant comme interlocuteur administratif.</small>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="CIVILITE" label-for="civilite">
                          <validation-provider #default="{ errors }" name="Civilite" :rules="form.gerant.civilite == null ? 'required' : ''">
                            <b-form-select id="civilite" v-model="form.gerant.civilite" :state="errors.length > 0 ? false : null">
                              <b-form-select-option selected :value="null">Selectionner une civilite</b-form-select-option>
                              <b-form-select-option v-for="civilite in civilites" :value="civilite.value" :key="civilite.value">{{ civilite.text }}</b-form-select-option>
                            </b-form-select>
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="PRÉNOM" label-for="prenom">
                          <validation-provider #default="{ errors }" name="Prénom" rules="required">
                            <b-form-input class="text-capitalize" id="prenom" v-model="form.gerant.prenom" placeholder="Prénom" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="NOM" label-for="nom">
                          <validation-provider #default="{ errors }" name="Nom" rules="required|min:2">
                            <b-form-input class="text-uppercase" id="nom" v-model="form.gerant.nom" placeholder="Nom" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="TÉLÉPHONE PORTABLE*" label-for="telephone_admin">
                          <div class="d-flex bd-highlight">
                           <div class="w-25 bd-highlight">
                            <v-select v-model="form.gerant.indicatif" :close-on-select="true" :clearable="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                              input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                            >
                              <template #option="{ indicatif,code }">
                                <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                              </template>
                              <template #selected-option="{ indicatif }">
                                <span> {{ indicatif }}</span>
                              </template>
                              <div slot="no-options">Aucune indicatif disponible.</div>
                          </v-select>
                          </div>
                      
                          <div class="w-75 bd-highlight">
                              <validation-provider #default="{ errors }" name="TÉLÉPHONE PORTABLE" rules="required|min:14" :rules="{ regex: /^(0|6|7)\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                                <b-form-input id="telephone_admin" v-mask="'## ## ## ## ##'" v-model="form.gerant.telephone_admin" placeholder="TÉLÉPHONE PORTABLE" type="tel" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="EMAIL" label-for="email">
                          <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input id="email" v-model="form.gerant.email_admin" placeholder="Email" type="email" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--fonction select -->
                      <b-col md="4">
                        <b-form-group label="Fonction" label-for="fonction">
                          <validation-provider #default="{ errors }" name="Fonctions" :rules="form.gerant.fonction == null ? 'required' : ''">
                            <b-form-select id="fonction" v-model="form.gerant.fonction" :state="errors.length > 0 ? false : null">
                              <b-form-select-option selected :value="null">Selectionner une fonction</b-form-select-option>
                              <b-form-select-option v-for="fonction in fonctions" :value="fonction.value" :key="fonction.value">{{ fonction.name }}</b-form-select-option>
                            </b-form-select>
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- <b-col md="4">
                        <b-form-group label="IDENTIFIANT DE CONNEXION" label-for="username">
                          <validation-provider #default="{ errors }" name="Identifiant de connexion" rules="required">
                            <b-form-input disabled id="username" v-model="form.gerant.username" placeholder="Identifiant" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                      <b-col md="4">
                        <b-form-group>
                          <label for="basic-password1">CREATION MOT DE PASSE </label>
                          <validation-provider #default="{ errors }" rules="required|password|min:12" name="Mot de passe" vid="password_admin">
                            <b-input-group class="input-group-merge">
                              <b-form-input id="basic-password1" :type="passwordGerant" placeholder="Mot de passe" :state="errors.length > 0 ? false : null" v-model="form.gerant.password" />
                              <b-input-group-append is-text>
                                <feather-icon :icon="passwordToggleIconGerant" class="cursor-pointer" @click="togglePasswordVisibilityG" />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label for="basic-password_confirmation">CONFIRMATION MOT DE PASSE</label>
                          <validation-provider #default="{ errors }" rules="required|confirmed:password_admin|min:12" name="confirmation mot de passe">
                            <b-input-group class="input-group-merge">
                              <b-form-input id="basic-password_confirmation" :type="passwordGerantCon" placeholder="Confirmation mot de passe" :state="errors.length > 0 ? false : null" v-model="form.gerant.passwordCon" />
                              <b-input-group-append is-text>
                                <feather-icon :icon="passwordToggleIconGerantCon" class="cursor-pointer" @click="togglePasswordVisibilityGC" />
                              </b-input-group-append>
                            </b-input-group>
                            <small v-if="errors[0] == 'confirmation de mot de passe n\'est pas identique'" class="text-danger">Le mot de passe n'est pas identique</small>
                            <small v-else class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                  </template>
              </b-col>
            </b-row>
            
          </validation-observer>
        </tab-content>

        <!-- paiement et Signature -->
        <tab-content title="Paiement" icon="feather icon-credit-card" CreditCardIcon :before-change="validationFormPayement">
          <validation-observer ref="payementRules" tag="form">
            <b-row>
              <b-col class="mx-auto" md="12" lg="8">
                <b-row>
                  <b-col md="8" lg="8">
                    <b-form-group label="Code promo" label-for="code_promo">
                          <p>Je dispose d'un code promo.</p>
                          <validation-provider name="code promo">
                          <b-form-input placeholder="Code promo" v-model="form.code_promo.code" id="code_promo" disabled/>
<!--                            <small class="text-danger text-lowercase text-left d-block mb-1" v-if="!form.code_promo.isExist">Ce code promo n’est pas valide.</small>-->
                          </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="form.code_promo != null && form.code_promo.code != null">
                  <b-col> 
                     <p class="text-justify">
                      <!-- , vous serez facturé par prélèvement SEPA au tarif de la formule choisie, selon les modalités précisées dans les <b><a href="#">Conditions Générales d’Utilisation</a>. -->
                       <span v-html="form.code_promo.description" /> code promo vous permet de bénéficier d’une <b>période d'essai gratuite de
                       {{ form.code_promo.valeur }} mois. Aucun paiement ne sera effectué avant la fin de cette période d'essai.</b>
                      </p>
                      <p class="text-justify">
                        Vous avez la faculté de <span class="font-weight-bolder">résilier votre adhésion</span> à tout moment durant cette période d’essai, en nous contactant à l’adresse <b><a href="mailto:support@bubble-in.com"><span>support@bubble-in.com</span></a></b>.
                      </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h6>COORDONNÉES BANCAIRES</h6>
                    <p class="text-justify" v-if="form.code_promo != null && form.code_promo.code != null">
                      <span class="font-weight-bolder">Au delà de la période d’essai,</span> vous serez facturé par prélèvement SEPA au tarif de la formule choisie, selon les modalités précisées dans les <b><a href="/cgu.pdf" target="_blank" >Conditions Générales d’Utilisation</a></b>. Nous procéderons au prélèvement de votre 1ère cotisation {{  form.compteBancaire.periodicite == 'ANNUEL' ?  'annuelle' : 'mensuelle'  }}, soit {{ convertTarif(form.compteBancaire.periodicite,form.tarifMonthly) }} euros le {{datePayement}}.
                    </p>
                    <p class="text-justify" v-else>Veuillez renseigner ci-dessous vos coordonnées bancaires pour le prélèvement de vos cotisations. Nous procéderons au prélèvement de votre 1ère cotisation {{  form.compteBancaire.periodicite == 'ANNUEL' ?  'annuelle' : 'mensuelle'  }}, soit {{ convertTarif(form.compteBancaire.periodicite,form.tarifMonthly) }} euros le {{datePayement}}.</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Mode de paiement" label-for="mode_de_paiement">
                      <b-form-select v-model="form.compteBancaire.mode_de_paiement" id="mode_de_paiement" disabled>
                        <b-form-select-option value="PREV" selected>Prélèvement</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="periodicite" label="Périodicité">
                      <template v-if="form.selected_pack == 1">
                        <b-form-select v-model="form.compteBancaire.periodicite" id="periodicite" disabled>
                          <b-form-select-option value="ANNUEL" selected>Annuelle</b-form-select-option>
                          <b-form-select-option value="MENSUEL">Mensuelle</b-form-select-option>
                        </b-form-select>
                      </template>
                      <template v-else>
                        <b-form-select v-model="form.compteBancaire.periodicite" id="periodicite">
                          <b-form-select-option value="ANNUEL" selected>Annuelle</b-form-select-option>
                          <b-form-select-option value="MENSUEL">Mensuelle</b-form-select-option>
                        </b-form-select>
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row> 
                <b-row>
                  <b-col md="12">
                    <b-form-group label-for="nom_titulaire" label="Nom du Titulaire">
                      <validation-provider #default="{ errors }" name="Nom du Titulaire" rules="required">
                        <!-- <b-form-input id="nom_titulaire" placeholder="Nom du Titulaire" v-model="form.cabinet.denomination_commercial" :state="errors.length > 0 ? false : null" disabled /> -->
                        <b-form-input list="nom_titulaire_list" id="nom_titulaire" placeholder="Nom du Titulaire" v-model="form.compteBancaire.nom_titulaire" :state="errors.length > 0 ? false : null"></b-form-input>

                        <datalist id="nom_titulaire_list">
                          <option v-for="titulaire in titulaire_list" :key="titulaire">{{ titulaire }}</option>
                        </datalist>

                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col md="6">
                    <b-form-group label-for="iban" label="IBAN">
                      <validation-provider #default="{ errors }" name="IBAN" rules="required">
                        <b-form-input id="iban" placeholder="IBAN" v-model="form.compteBancaire.iban" :state="errors.length > 0 || (!isValidIban && form.compteBancaire.iban.length > 16 ) ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                        <small class="text-danger text-lowercase" v-if="!isValidIban && form.compteBancaire.iban.length > 16">veuillez saisir un iban valide</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label-for="bic" label="BIC">
                      <validation-provider #default="{ errors }" name="BIC" rules="required">
                        <b-form-input id="bic" placeholder="BIC" v-model="form.compteBancaire.bic" :state="errors.length > 0 || (!isValidBic && form.compteBancaire.bic.length >= 6 ) ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                        <small class="text-danger text-lowercase" v-if="!isValidBic && form.compteBancaire.bic.length >= 6">veuillez saisir un bic valide</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <h6>INFORMATIONS</h6>
                    <p class="text-justify">Pour information, seuls les RIB professionnels sont acceptés sauf dans le cas d’une Entreprise Individuelle ou un RIB personnel est envisageable.</p>
                    <p class="text-justify">L’utilisation de la solution BubbleIn est soumise à l’adhésion à l’association La Plateforme du Courtage.</p>
                    <p class="text-justify">Voici la liste des documents dont vous devez avoir pris connaissance en vue de cette adhésion et de l’utilisation des services de la solutions.</p>
                  </b-col>
                  <b-col md="4" class="text-center">
                    <b-link href="/cgu.pdf" target="_blank" class="font-weight-bolder">Conditions Générales d'Utilisation</b-link>
                  </b-col>
                  <b-col md="4" class="text-center">
                    <b-link href="/slpdc.pdf" target="_blank" class="font-weight-bolder">Statuts de l'association</b-link>
                  </b-col>
                  <b-col md="4" class="text-center">
                    <b-link href="/rilpdc.pdf" target="_blank" class="font-weight-bolder">Règlement intérieur de l'association</b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- <tab-content title="Signature" icon="feather icon-credit-card">
          
        </tab-content> -->
      </form-wizard>
    </div>
    
  </div>
  <div class="col-lg-3 col-sm-12 side-promo-parten">
    <b-card class="code-promo-card" v-if="code_promo_show">
                    <h4 class="text-primary">
                      <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" /> CODE PROMO</h4>
                    <b-card-text style="font-size:0.9rem;">Adhésion réalisée avec le code {{form.code_promo.code}} vous permettant de <span class="text-primary" style="font-weight: 900!important;font-size:1.1rem;">bénéficier de {{ form.code_promo.valeur }}  mois gratuits.</span> </b-card-text>

    </b-card>
    <b-card class="code-promo-card position-relative" v-if="produits.length>0">
                    <h4 class="text-primary"><feather-icon
                      class="box-icon-text"
                      icon="PackageIcon"
                      size="20"
                    /> PRODUITS</h4>

 
                    <b-row class="mt-1 parteners_area">
                      <b-col md="12">
                        <!-- Slider main container -->
                        <div class="swiper-container" ref="swiperContainer">
                          <!-- Additional required wrapper -->
                          <div class="swiper-wrapper">
                            <!-- Slides -->
                            <div v-for="(item,i) of produits" :key="i" class="swiper-slide border" style="padding: 7px 15px;text-align: center;" >
                              <h4 class="text-primary text-uppercase" v-html="item.svg"></h4>
                              <img  :src="item.assureur_image"  height="70px" alt="Assureur image"  style="max-width:100%;">
                              <p v-html ="item.first_paragraph"> </p>
                              <p style="font-weight: 900!important;" class="text-primary">{{ item.details }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-next text-center">
                            <feather-icon size="40" class="mr-50" icon="ChevronDownIcon" />
                        </div>
                      </b-col>
                    </b-row>
                 
    </b-card>
   
  </div>
  </b-row>
  <div style="margin-top: 0;" class="contacts-info">
                    <p class=" mb-0 font-italic font-weight-bold">Pour contacter le support :</p>
            <p class="font-italic small mb-0">- Téléphone : <a href="tel:0175952299" class="font-weight-bolder">01 75 95 22 99</a></p>
            <p class="font-italic small mb-0">- Email : <a href="mailto:support@bubble-in.com" class="font-weight-bolder">support@bubble-in.com</a></p>
            <p class="font-italic small ">Vous pouvez également utiliser le bouton Assistance/Chat en bas à droite</p>
                  </div>
  </b-container>

   <!-- Footer -->
    <footer
      class="footer"
      style="background-color: #160938"
      
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->
</div>
</template>
<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import AppFooter from "@core/layouts/components/AppFooter.vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required, email, confirmed, password, integer, digits } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormSelect, BFormSelectOption, BFormRadioGroup, BFormRadio, BFormCheckbox, //BCarousel, BCarouselSlide,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BImg,
    BLink,
    BModal,
    BAlert,
    BContainer
    } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import enums from '../shared/constants/prospect'
import axios from 'axios'
import url from '@/shared/api/parametre'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import {is422} from '@/shared/utils/response'
import Swiper from 'swiper'
import 'swiper/swiper.scss'

export default {
  components: {
    BContainer,
    // BCarousel,
    // BCarouselSlide,
    // Swiper,
    // SwiperSlide,
    AppFooter,
    AppCollapse,
    AppCollapseItem,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BImg,
    BLink,
    BModal
  },
  // mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  data() {
    return {
      produits: [],
      varcheck: 0,
      code_promo_show:false,
      disabled_orias: false,
      indicatif: [],
      datePayement:null,
      titulaire_list: [],
      isValidIban: false,
      isValidBic: false,
      //// validation rules
      required,
      showAlertCodePromo:false,
      email,
      confirmed,
      password,
      integer,
      digits,
      //// step 1
      Form_Juridique: enums.FORMEJURIDIQUE,
      Nombre_employes : enums.NOMBRE_EMPLOYES,
      Villes: [],
      loading: false,
      adresseResult: [],
      villesCourtier: [],
      oriasMessage: '',
      showcabinet:false,
      form: {
        courtier_id:null,
        cabinet:{
          numero_orias: '',
          raison_sociale: '',
          numero_siren: '',
          denomination_commercial: '',
          date_enregistrement: '',
          lieu_rcs: '',
          telephone_cabinet: '',
          indicatif: '',
          email_cabinet: '',
          form_juridique: null,
          nbr_salary:null,
          capital: '',
          num_libelle: '',
          lieu_dit_ou_bp: '',
          adresse: '',
          complement_adresse: '',
          code_postal: '',
          ville: null,
        },
        
        //////step 2
        dirigeant:{
          civilite: null,
          prenom: '',
          nom: '',
          username: '',
          telephone_admin: '',
          indicatif: '',
          email_admin: '',
          fonction: 'Responsable',
          password: '',
          passwordCon: '',
        },
        gerant:{
          civilite: null,
          prenom: '',
          nom: '',
          username: '',
          telephone_admin: '',
          indicatif: '',
          email_admin: '',
          fonction: null,
          password: '',
          passwordCon: '',
        },
        //////step 4
        compteBancaire:{
          mode_de_paiement: 'PREV',
          periodicite: 'ANNUEL',
          bic: '',
          iban: '',
          nom_titulaire: null,
        },
        is_administrateur: 'oui',
        is_annuairebubble: true,
        code_promo: {code: null, designation: null, valeur: null},
        selected_pack: null,
        pack_name : null,
        tarifMonthly:null,
      },
      /////// step 2
      civilites: [
        {
          text: 'M.',
          value: 'MR'
        },
        {
          text: 'Mme.',
          value: 'MME'
        }
      ],
      
      fonctions: enums.FONCTIONS,
      // formule step 4
      pricing: {},
      packs: {},
      popup_signature:false,
      orias_isVerified:false,
      passwordDiregent: 'password',
      passwordDiregentCon: 'password',
      passwordGerant: 'password',
      passwordGerantCon: 'password',
      validorias: true,
      number: 0,
      carrosel_show:[],
    }
  },
  mounted() {
    this.getLogoName()
    let host = window.location.host;
    if (host.includes("erp.bubble-in.com") || host.includes("test.bubble-in.com") || host.includes("demo.bubble-in.com")) {
      let recaptchaScriptzdassets = document.createElement('script')
      recaptchaScriptzdassets.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=1b1c5fe8-19aa-487f-bf66-4ecc83d2124c`)
      recaptchaScriptzdassets.setAttribute('id', `ze-snippet`)
      document.head.appendChild(recaptchaScriptzdassets)
    }

    
  },
  computed: {
    passwordToggleIconDiregent() {
      return this.passwordDiregent === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconDiregentCon()
    {
      return this.passwordDiregentCon === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconGerant() {
      return this.passwordGerant === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconGerantCon()
    {
      return this.passwordGerantCon === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  watch: {
    'form.compteBancaire.iban': {
      immediate:true,
      handler(val){
        this.checkiban(val)
      }
    },
    'form.compteBancaire.bic': {
      immediate:true,
      handler(val){
        this.checkbic(val)
      }
    },
    'form.cabinet.numero_orias': {
      // immediate: true,
      deep:true,
      handler() {
        this.orias_isVerified = false;
        this.showcabinet = false;
        for (var key in this.form.cabinet ) {
          if(key != "numero_orias" && key != "denomination_commercial")
          {
            this.form.cabinet[key] = null;
          }
        }

        // this.form.cabinet.numero_siren = null
        // this.form.cabinet.raison_sociale = null
        this.oriasMessage = ''
        this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.remove("show_btn");
        this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("hide_btn");
      }
    },
    'form.cabinet.code_postal': {
      immediate: true,
      handler(val) {
        this.GetVille(val)
      }
    },
    'form.cabinet.telephone_cabinet': {
      immediate: true,
      handler(val) {
        if(!this.orias_isVerified)
        {
          this.CheckTel(val)
        }
        
      }
    },
    'form.gerant.telephone_admin':
    {
      immediate: true,
      handler(val) {
        this.CheckTel(val)
      }
    },
    // 'form.dirigeant.nom':{
    //   immediate: true,
    //   handler(val) {
    //     this.loginPreDirigeant(val)
    //   }
    // },
    // 'form.dirigeant.prenom':{
    //   immediate: true,
    //   handler(val) {
    //     this.loginPreDirigeant(val)
    //   }
    // },
    // 'form.gerant.nom':{
    //   immediate: true,
    //   handler(val) {
    //     this.loginPre(val)
    //   }
    // },
    // 'form.gerant.prenom':{
    //   immediate: true,
    //   handler(val) {
    //     this.loginPre(val)
    //   }
    // },
    'form.code_promo':{
      deep: true,
      handler(val) {
        // check is code promo valid then show code promo bloc in right side

         if(val.code != null){
            //this.checkerCodePromo(this.form.code_promo.code, false);
            this.code_promo_show = true;
          }
         else{
           this.form.code_promo = {code: null, designation: null, valeur: null};
           this.code_promo_show = false; }

        let today = new Date();
        if(val.code == "BubbleParrainage6M" || val.code == "BUBBLEWDM" || val.code=="BUBBLE2022" || val.code=="BUBBLEC1C" || val.code=="BUBBLE6M"){
          if(today.getDate() >= 27 && today.getDate() <= 31)
          {
            today = new Date(today.setMonth(today.getMonth()+8));
          }
          else
          {
            today = new Date(today.setMonth(today.getMonth()+7));
          }
        }
        else if(val.code == 'BUBBLE2M')
        {
          
          if(today.getDate() >= 27 && today.getDate() <= 31)
          {
            today = new Date(today.setMonth(today.getMonth()+4));
          }
          else
          {
            today = new Date(today.setMonth(today.getMonth()+3));
          }
        }
        else if(val.code == 'BUBBLE1AJDC' || val.code=='BUBBLEWDM12')
        {
          
          if(today.getDate() >= 27 && today.getDate() <= 31)
          {
            today = new Date(today.setMonth(today.getMonth()+14));
          }
          else
          {
            today = new Date(today.setMonth(today.getMonth()+13));
          }
        }
        else
        {

          today = new Date();
          
          if(today.getDate() >= 27 && today.getDate() <= 31)
          {
            today = new Date(today.setMonth(today.getMonth()+2));
          }
          else
          {
            today = new Date(today.setMonth(today.getMonth()+1))
          }
        }
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        this.datePayement = 10 + '/' + mm + '/' + yyyy;
      }
    },
   
  },
  methods: {
    checkerCodePromo(code, isEncoding = true) {
      let codePromo = code ? code : "BUBBLE2M"
      let codePromoIsEncoding = code ? isEncoding : false
        this.$http.post(`packs/checkerCodePromo`, { "code" : codePromo, "isEncoding" : codePromoIsEncoding})
            .then((r) => {
              this.form.code_promo = r.data.data;
            })
            .catch((err) => {
              console.log(err);
              this.form.code_promo = {code: null, designation: null, valeur: null};
            });

},
    async setStatistique(step) {
      if(this.form.cabinet.denomination_commercial == null || this.form.cabinet.denomination_commercial == ""){
        this.form.cabinet.denomination_commercial = this.form.cabinet.raison_sociale
      }
      let data = {
        'numero_orias': this.form.cabinet.numero_orias,
        'denomination_commercial': this.form.cabinet.denomination_commercial,
        'step': step,
        'formule': this.form.selected_pack
      }

      if(step === 'PAIEMENT') {
        data.nom    = this.form.dirigeant.nom
        data.prenom = this.form.dirigeant.prenom
        data.tel    = this.form.dirigeant.telephone_admin
        data.email  = this.form.dirigeant.email_admin
      }

      await axios
      .post(`${url}statistiqueAdhesion`, data).then(response => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('event', 'Register', {
          'event_category': 'Register',
          'event_label': data.denomination_commercial,
          'value': step
        })
      })
    },
    getPays(){
      this.$http.get(`/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
      })
      .catch((e)=>{

        console.log(e);

      })
    },
    initializeSwiper() {
      
     new Swiper('.swiper-container', {
          direction: 'vertical',
          mousewheelControl: true,
          slidesPerView: 2,
          spaceBetween: 3,
          loop: true,
          speed: 2000,
          navigation: {
            nextEl: '.swiper-button-next',
          },
          autoplay: {
            delay: 1000,
            disableOnInteraction: false
          },
        })
       },
    getLogoName() {
            this.showLoading = true
            this.$http.get(`getLogoProduits`)
            .then((res) => {
              this.produits = res.data
              this.showLoading = false
              this.sortProducts();
              this.produits.forEach((produit) => {
                if (produit.id == '19') {
                  produit.first_paragraph = '<strong>CAPITAL SENIOR	</strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '23') {
                  produit.first_paragraph = '<strong>SERENISSIA </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '24') {
                  produit.first_paragraph = '<strong>ACTISAN </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '27') {
                  produit.first_paragraph = '<strong>SPVIE Santé Malin </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '28') {
                  produit.first_paragraph = '<strong>SPVIE Santé Malin </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '32') {
                  produit.first_paragraph = "<strong>INSUR'ACTIF </strong><br>Cible et âge de souscription :"
                }
                else {
                  produit.first_paragraph = 'Cible et âge de souscription :'
                }
              })

            })
            .catch((e) => {
                console.log(e);
                this.showLoading = false
            }).finally(() => {
                if(this.produits.length > 0) {
                  console.log('==== exec swiper ====')
                  this.initializeSwiper()
                }
            })
    },
    sortProducts() {
         
        const order = ['ADP', 'ADP2', 'IARD'];
        const adpProducts = this.produits.filter(product => product.type === 'ADP');
        adpProducts.sort((a, b) => a.nom_com_assureur.localeCompare(b.nom_com_assureur));
        adpProducts.sort((a, b) => b.prefixe.localeCompare(a.prefixe));

        const nonAdpProducts = this.produits.filter(product => product.type !== 'ADP');
        nonAdpProducts.sort((a, b) => order.indexOf(a.nom_com_assureur) - order.indexOf(b.nom_com_assureur));
        nonAdpProducts.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));

        this.produits = [...adpProducts, ...nonAdpProducts];
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    selectPack(id,nom,tarif){
      this.form.selected_pack = id;
      this.form.pack_name = nom
      this.form.tarifMonthly = tarif;
      console.log("*****",this.form.tarifMonthly);
      console.log("*****",parseFloat(this.form.tarifMonthly));
      if(this.form.selected_pack == 1)
      {
        this.form.compteBancaire.periodicite = 'ANNUEL'
      }
      this.$refs.registerwizard.nextTab()
      //this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("show_btn");
    },
    // loginPre(){
    //   if(this.form.gerant.nom.length >= 2 && this.form.gerant.prenom.length >= 2)
    //   {
    //      this.$http
    //         .post(`checklogin`, {'nom':this.form.gerant.nom,'prenom':this.form.gerant.prenom})
    //         .then((r) => {
    //           this.form.gerant.username = r.data.username.toLowerCase();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //   }
    // },
    // loginPreDirigeant(){
    //   if(this.form.dirigeant.nom.length >= 2 && this.form.dirigeant.prenom.length >= 2)
    //   {
    //     this.$http
    //         .post(`checklogin`, {'nom':this.form.dirigeant.nom,'prenom':this.form.dirigeant.prenom})
    //         .then((r) => {
    //           this.form.dirigeant.username = r.data.username.toLowerCase();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //   }
    // },
    async formSubmitted() {
      this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button.show_btn').disabled = true;
      axios
        .post(`${url}registerCourtier`, this.form)
        .then(response => {
          if(response.data.code == 200)
          {
            let courtier = response.data.courtier;
            let user = response.data.user;
            axios
            .post(`${url}signatureCourtier`,{'courtier': courtier,'dataForm': this.form,'user':user})
            .then(response => {
                if(response.data.code == 200)
                {
                  let urlSignature = response.data.url
                  this.setStatistique('SIGNATURE').then(() => {
                    window.open(urlSignature,"_self");
                  })
                }
            }).catch(error => {
              this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button.show_btn').disabled = false;
            });
          }
        })
        .catch(error => {
          this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button.show_btn').disabled = true;
          this.$toast({
                component: ToastificationContent,
                props: {
                title: 'Inscription',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'danger',
                },
              },
            );
        });
        // this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //     title: 'Form Submitted',
        //     icon: 'BellIcon',
        //     variant: 'success',
        //     },
        // })
    },
    validationPackForm()
    {
      return new Promise((resolve, reject) => {
        this.$refs.packRules.validate().then(success => {
          if (this.form.selected_pack) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            if(this.validorias)
            {
              if(this.form.cabinet.denomination_commercial == null || this.form.cabinet.denomination_commercial == ""){
                this.form.cabinet.denomination_commercial = this.form.cabinet.raison_sociale
              }
              if(this.form.cabinet.telephone_cabinet){
                if(this.form.cabinet.telephone_cabinet.startsWith("06") || this.form.cabinet.telephone_cabinet.startsWith("07")){
                  this.form.dirigeant.indicatif = this.form.cabinet.indicatif
                  this.form.dirigeant.telephone_admin = this.form.cabinet.telephone_cabinet
                }
              }
              this.form.dirigeant.email_admin = this.form.cabinet.email_cabinet
              this.titulaire_list = [];
              this.titulaire_list.push(this.form.cabinet.denomination_commercial);
              this.titulaire_list.push(this.form.cabinet.raison_sociale);
              this.setStatistique('CONTACT')
              resolve(true)
            }
            else
            {
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            if(this.form.dirigeant.civilite == null || this.form.dirigeant.nom == null || this.form.dirigeant.prenom == null || this.form.dirigeant.telephone_admin == null || this.form.dirigeant.email_admin == null)
            {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Inscription',
                  icon: 'BellIcon',
                  text: 'veuillez remplir tous les champs obligatoires',
                  variant: 'danger',
                  },
                },
              );

              reject()
            }
            else
            {
              this.setStatistique('PAIEMENT')
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },
    // validationAdhesion() {
    //   return new Promise((resolve, reject) => {
    //     this.$refs.adhesionRules.validate().then(success => {
    //       if (success) {
    //         this.setStatistique('PAIEMENT')
    //         resolve(true)
    //       }
    //     })
    //   })
    // },
    validationFormPayement() {
      if(this.form.code_promo.length > 0)
      {
        if(this.form.code_promo && this.form.code_promo.code)
        {
          return new Promise((resolve, reject) => {
            this.$refs.payementRules.validate().then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
          });
        }
        else
        {
          return new Promise((resolve, reject) => {
            this.$refs.payementRules.validate().then(success => {
                reject();
            })
          })
        }
      }
      else
      {
        return new Promise((resolve, reject) => {
          this.$refs.payementRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
    },
    GetOrias() {
      this.disabled_orias = true
      axios
        .post(`${url}verify/orias`, {
          orias: this.form.cabinet.numero_orias
        })
        .then((r) => {
          axios
            .get(`${url}orias`, {
              params: {
                numero_orias: this.form.cabinet.numero_orias
              }
            })
            .then(res1 => {
              this.setStatistique('ORIAS')
              if (res1.data.data.pass) {
                //eslint-disable-next-line no-underscore-dangle
                this.form.cabinet.raison_sociale = this.$_.get(res1.data.data, 'denomination', null)
                //eslint-disable-next-line no-underscore-dangle
                this.form.cabinet.numero_siren = this.$_.get(res1.data.data, 'siren', null)
                //eslint-disable-next-line no-underscore-dangle
                this.form.cabinet.date_enregistrement = this.$_.get(res1.data.data, 'registrationDate', null)

                axios
                .post(`${url}getEnregistrementBySiren`, {
                  siren: this.form.cabinet.numero_siren
                }).then(res2 => {
                  if(res2.data.data){
                    this.form.cabinet.num_libelle = res2.data.data.numVoie+' '+res2.data.data.typeVoie+' '+res2.data.data.voie
                    this.form.cabinet.num_libelle = this.form.cabinet.num_libelle.replace(/\s+/g,' ').trim().toLowerCase();
                    this.form.cabinet.code_postal = res2.data.data.codePostal
                    // this.form.cabinet.ville = res2.data.data.ville
                    this.form.cabinet.denomination_commercial = res2.data.data.denomination
                    this.form.cabinet.capital = res2.data.data.capital
                    this.form.cabinet.form_juridique = res2.data.data.forme_juridique
                    this.form.dirigeant.nom = res2.data.data.nom.toLowerCase()
                    this.form.dirigeant.prenom = res2.data.data.prenom.toLowerCase()
                    this.validorias = true
                    this.orias_isVerified = true
                    this.showcabinet = true
                    this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("show_btn");
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Le numéro d'orias est valide.",
                        icon: 'BellIcon',
                        variant: 'success'
                      }
                    })
                    this.disabled_orias = false
                  }
                }).catch(err => {
                  this.validorias = true
                    this.orias_isVerified = true
                    this.showcabinet = true
                    this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("show_btn");
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Le numéro d'orias est valide.",
                        icon: 'BellIcon',
                        variant: 'success'
                      }
                    })
                  this.disabled_orias = false
                })
              } else {
                this.validorias = false;
                this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("hide_btn");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Le numéro d'orias est invalide.",
                    icon: 'BellIcon',
                    variant: 'danger'
                  }
                })
                this.disabled_orias = false
              }
            })
            .catch(err => {
              this.disabled_orias = false
              console.log('serveur down');
              this.validorias = true
              this.orias_isVerified = true
              this.showcabinet = true
              this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("show_btn");
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: err.response.data.errors.join('<br>'),
              //     icon: 'BellIcon',
              //     variant: 'danger'
              //   }
              // })
            })
        })
        .catch(err => {
          console.log(err)
          axios
                .get(`${url}getcourtierbyorias/`, {
                  params: {
                    orias: this.form.cabinet.numero_orias
                  }
                }).then(response => {
                  if(response.data.success)
                  {
                      this.validorias = true
                      this.orias_isVerified = true
                      this.showcabinet = true
                      this.form.cabinet.raison_sociale = response.data.success.personnemoral.raison_sociale
                      this.form.cabinet.numero_siren = response.data.success.personnemoral.siren
                      this.form.cabinet.denomination_commercial = response.data.success.personnemoral.denomination_commercial
                      this.form.cabinet.telephone_cabinet = response.data.success.moyencontact.tel
                      this.form.cabinet.email_cabinet = response.data.success.moyencontact.email
                      this.form.cabinet.form_juridique = response.data.success.personnemoral.forme_juridique
                      this.form.cabinet.nbr_salary = response.data.success.personnemoral.effectif
                      this.form.cabinet.capital = response.data.success.personnemoral.capital_social
                      this.form.cabinet.lieu_rcs = response.data.success.personnemoral.num_rcs

                      this.form.cabinet.num_libelle = response.data.success.moyencontact.libelle
                      this.form.cabinet.lieu_dit_ou_bp = response.data.success.moyencontact.lieu_dit_ou_bp
                      this.form.cabinet.complement_adresse = response.data.success.moyencontact.complement_adresse
                      this.form.cabinet.code_postal = response.data.success.moyencontact.code_postal
                      this.form.cabinet.ville = response.data.success.moyencontact.ville_id

                      this.form.cabinet.date_enregistrement = response.data.success.courtier.date_inscription_orias

                      this.form.courtier_id = response.data.success.courtier.id
                      this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("show_btn");
                  }
                  else if(response.data.error_14)
                  {
                    this.$swal({
                        title: 'Votre inscription est déjà en cours. Vous avez reçu un lien de signature par email de la part de notre partenaire Universign. Nous vous invitons à cliquer ci-dessous pour finaliser votre inscription.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: "Signer mon bulletin d'adhésion",
                        className: 'relance_email',
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      }).then(result => {
                        if (result.value) {
                          window.location.href = response.data.convention_url;
                        }
                      });
                      this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("hide_btn");
                  }
                  else{
                    this.$refs.registerwizard.$el.querySelector('.wizard-footer-right button').classList.add("hide_btn");
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: err.response.data.errors.orias[0],
                        icon: 'BellIcon',
                        variant: 'danger'
                      }
                    })
                  }
                });
          this.disabled_orias = false
        })
    },
    GetVille(code) {
      if (code && code.length === 5) {
        axios
          .get(`${url}helpers/${code}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.Villes = [...res.data]
               if (this.form.cabinet.ville == null) {
              this.form.cabinet.ville = this.Villes[0].id
            }
              // this.form.cabinet.ville = this.Villes.find((obj) => { return obj.ville.toUpperCase().replace('-', ' ') == this.form.cabinet.ville.toUpperCase().replace('-', ' ')}).id
            }
            // console.log(this.Villes)
          })
          .catch(err => {})
      } else {
        this.Villes = []
      }
    },
    CheckTel(tel) {
      if (tel && tel.length == 14) {
        const regex = /(05|06|07)(?:[\s.-]*\d{2}){4}/gm
        if (regex.test(tel)) {
          axios
            .get(`${url}helpers/check_tel/${tel}`)
            .then(res => {
              if (res.data > 0) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ce numéro de téléphone est déjà rattaché à un client. Merci de saisir un nouveau n° de téléphone.',
                    variant: 'danger'
                  }
                })
              }
            })
            .catch(err => {})
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ce numéro de téléphone est erroné. Merci de saisir un nouveau n° de téléphone.',
              variant: 'danger'
            }
          })
        }
      }
    },
    checkiban(iban){
      if(iban.length > 16)
      {
        axios
        .post(`${url}checkiban`, {
          iban: this.form.compteBancaire.iban
        })
        .then((r) => {
          this.isValidIban = r.data.response;
        }).catch(err => {
          console.log(err)
        })
      }
    },
    checkbic(bic){
      if(bic.length >= 6)
      {
        axios
        .post(`${url}checkbic`, {
          bic: this.form.compteBancaire.bic
        })
        .then((r) => {
          this.isValidBic = r.data.response;
        }).catch(err => {
          console.log(err)
        })
      }
    },
    convertTarif(period,tarif){
      if(tarif){

        let tarifNew  = tarif.replace(",", ".");
        console.log(tarifNew);
        switch (period) {
          case 'ANNUEL': 
            return (parseFloat(tarifNew) * 12).toFixed(2)
          case 'MENSUEL':
            return tarif; 
          default:
            break;
        }
      }
    },
    togglePasswordVisibilityD() {
      this.passwordDiregent = this.passwordDiregent === 'password' ? 'text' : 'password'
    },
    togglePasswordVisibilityDC() {
      this.passwordDiregentCon = this.passwordDiregentCon === 'password' ? 'text' : 'password'
    },
    togglePasswordVisibilityG() {
      this.passwordGerant = this.passwordGerant === 'password' ? 'text' : 'password'
    },
    togglePasswordVisibilityGC() {
      this.passwordGerantCon = this.passwordGerantCon === 'password' ? 'text' : 'password'
    },
    getValuePromoByCode(code) {
      switch (code) {
        case 'BUBBLE2M':
          return '2 mois gratuits'
        case 'BUBBLE2022':
        case 'BUBBLEWDM' :
        case 'BUBBLEC1C' :
          return '6 mois gratuits'
        case 'BUBBLE1AJDC':
        case 'BUBBLEWDM12':
          return '12 mois gratuits'
        default:
          return ''
      }
    },
    // showBande(code) {
    //   switch (code) {
    //     case 'BUBBLE2M':
    //     case 'BUBBLE2022':
    //     case 'BUBBLEWDM' :
    //     case 'BUBBLEC1C' :
    //     case 'BUBBLE1AJDC':
    //     case 'BUBBLEWDM12':
    //       return true
    //     default:
    //       return false
    //   }
    // },
  },
  created() {
    this.getPays()
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    localize('fr')
    this.$http.get(`packs`)
        .then((r) => {
          this.packs = r.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    this.checkerCodePromo(code, true);


    // const code_final = window.atob(code);
    //
    // if(code_final == 'BUBBLE2022' || code_final == 'BUBBLE2M' || code_final == 'BUBBLEWDM' || code_final == 'BUBBLE1AJDC' || code_final == 'BUBBLEC1C' || code_final == 'BUBBLEWDM12')
    // {
    //   this.form.code_promo = code_final;
    //
    // }
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>

<style>
.swiper-container{
  width: 100%;
  height: 350px;
}
.swiper-button-next {

}
.register .wizard-nav-pills {
  justify-content: center;
}
.demo-inline-spacing > *
{
  margin-top: 0;
}
.footer
{
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer, .footer a
{
  color: #fff;
}
.formule_detail .card .card-header
{
  justify-content: center;
}
.form-group label
{
  font-size: 0.8rem;
  font-weight: 700;
}
::placeholder
{
  text-transform: capitalize;
}
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show
{
  width:80%;
  height: 80%;
}
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show h2
{
  font-size: 1.5rem;
}
.visibility_hidden
{
  visibility: hidden;
}
small.text-danger.text-lowercase
{
  display: inline-block;
}
small::first-letter
{
  text-transform: uppercase;
}
.list-group .list-unstyled
{
  font-size: 0.9rem;
}
.vue-form-wizard .wizard-footer-right .wizard-btn
{
  display: none;
}
.hide_btn
{
  display: none !important;
}
.show_btn
{
  display: inline-block !important;
}

/*css indicatif */

.indicatif-chooser .vs__dropdown-toggle {

  border-radius: 0.357rem 0px 0px 0.357rem;

  padding-bottom: 4.5px;

}

.v-select.indicatif-chooser.vs--open.vs--single.vs--searchable

{

  min-width: 20%;

  width:20%;

}

input#indicatif.vs__search{

  padding: 0px;

}

.vs__selected-options{

  height: 31px;

}
 .vs--single .vs__dropdown-toggle {
    padding-left: 0px;
/* css indicatif */
 }
.formule_detail .lead.collapse-title{
  font-weight: bold;
  border-bottom: 1px solid black;
 }
 .code-promo-card .card-body{
  padding: 2rem;
 }
#launcher{
  bottom: 50px !important;
  left: 90vw !important;
}
.pricing-card .annual-plan .plan-price span{
  font-size: 2rem;
}
 @media only screen and (min-width: 992px) {
  .side-promo-parten{
  margin-top: 190px;
  padding-left: 1rem !important;
}
}
 @media only screen and (max-width: 790px) {
  .side-promo-parten{
  margin-top: 0;
  padding-left: 1rem !important;
}
}
 @media only screen and (max-width: 767px) {
  .side-promo-parten{
  margin-top: 0px;
}


#launcher{
  left: 85vw !important;
}
}

.contacts-info{
  min-height: 200px;
}

/* #webWidget{
  left: 80% !important;
} */
.parteners_area p{
  margin-bottom: 0 !important;
}
.partener_mention{
  color: black !important;
  font-weight: 500;
  font-size:0.9rem;
}
.partener_mention_reponse{
  font-weight: 600;
}
.title-partenaire{
margin-left: 5px; 
font-size: 18px;
}

.iconDown:hover{
  color: black;
  cursor: pointer;
}
</style>
